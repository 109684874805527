
export default {
  name: 'List',
  props: {
    unlazyFirstImage: {
      type: Boolean,
      default: false,
    },
    posts: {
      type: Array,
      required: true,
    },
    highlights: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
}
